import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { getGames } from './client';
import { Game } from './api';
import SingleGameView from './SingleGameView';

const GameView: React.FC<{
  admin: boolean;
  active: boolean;
  refresh: number;
  triggerRefresh: () => void;
}> = ({ admin, active, refresh, triggerRefresh }) => {
  const [games, setGames] = useState<Game[]>([]);
  const [round, setRound] = useState(0);
  const [editGame, setEditGame] = useState<Game>();
  const [refreshing, setRefreshing] = useState(false);
  const [amountRounds, setAmountRounds] = useState(0);
  const localRefresh = useRef(refresh);
  const refreshGames = useCallback(() => {
    setRefreshing(true);
    getGames().then((g) => {
      setGames(g.games);
      setAmountRounds(g.rounds);
      setRefreshing(false);
    });
  }, []);
  useEffect(() => {
    if (refresh === 0 || refresh > localRefresh.current) {
      refreshGames();
      localRefresh.current = refresh;
    }
    const cancel = setInterval(() => {
      refreshGames();
    }, 60000);
    return () => {
      clearInterval(cancel);
    };
  }, [refresh, refreshGames]);
  const onRoundClicked = useCallback((r: number) => {
    setRound(r);
  }, []);
  const onSetEdit = useCallback(
    (id: number) => () => {
      setEditGame(games.find((g) => g.id === id));
    },
    [games],
  );
  const onCloseEdit = useCallback(
    (r: boolean) => {
      setEditGame(undefined);
      if (r) {
        triggerRefresh();
      }
    },
    [triggerRefresh],
  );
  let currentRound = 0;
  return (
    <div className={`games${active ? ' active' : ''}`}>
      {editGame && (
        <SingleGameView close={onCloseEdit} admin={admin} game={editGame} />
      )}
      <h2>
        Punkte-Phase
        {'  '}
        {refreshing && (
          <div className="refreshing">
            <FontAwesomeIcon icon={faRefresh} />
          </div>
        )}
      </h2>
      <div className="row">
        {Array(amountRounds)
          .fill(0)
          .map((_, i) => (
            <button
              key={'round-' + i}
              type="button"
              onClick={() => {
                onRoundClicked(i + 1);
              }}>
              Runde {i + 1}
            </button>
          ))}
      </div>
      <table>
        <thead>
          <tr>
            <th>Spiel</th>
            <th>Stand</th>
            {admin && <th>Aktion</th>}
          </tr>
        </thead>
        <tbody>
          {games
            .filter((g) => round === 0 || g.gameRound === round)
            .map((g) => {
              let roundTd = null;
              if (!!g.gameRound && g.gameRound !== currentRound) {
                currentRound = g.gameRound;
                roundTd = (
                  <tr id={`round-${g.gameRound}`} className="round">
                    <td colSpan={2}>
                      <span>
                        Runde
                        {g.gameRound}
                      </span>
                    </td>
                  </tr>
                );
              }
              return (
                <React.Fragment key={`${g.team1.id}-${g.team2.id}`}>
                  {roundTd}
                  <tr>
                    <td>
                      <div className="game-name">
                        <span>
                          Tisch
                          {Math.floor(g.team2.number / 2)}
                        </span>
                        <div className={'teams'}>
                          <span>
                            {` ${g.team1.name}`}
                            <strong>[{g.team1.number}]</strong>
                          </span>
                          <strong className="vs">vs.</strong>
                          <span>
                            {` ${g.team2.name}`}
                            <strong>[{g.team2.number}]</strong>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="score">
                        <span>
                          <br />
                        </span>
                        <strong>{g.score1 ?? 0}</strong>
                        <span> - </span>
                        <strong> {g.score2 ?? 0}</strong>
                      </div>
                    </td>
                    {admin && (
                      <td>
                        <button
                          type="button"
                          onClick={onSetEdit.call(this, g.id)}>
                          <FontAwesomeIcon icon={faPencil} />
                        </button>
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default GameView;

// s s
