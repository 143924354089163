import React, { useCallback, useState } from 'react';
import { patchGame, resetGame } from './client';
import { Game } from './api';

const SingleGameView: React.FC<{
  close: (refresh: boolean) => void;
  admin: boolean;
  game: Game;
}> = ({ admin, game, close }) => {
  const [team1, setTeam1] = useState(game.score1 ?? 0);
  const [team2, setTeam2] = useState(game.score2 ?? 0);
  const onSetTeam1 = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setTeam1(Math.max(0, Math.min(15, parseInt(e.target.value, 10))));
    },
    [],
  );
  const onSetTeam2 = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setTeam2(Math.max(0, Math.min(15, parseInt(e.target.value, 10))));
    },
    [],
  );
  const onSave = useCallback(() => {
    if (team1 === 0 && team2 === 0) {
      resetGame(game.id).finally(() => {
        close(true);
      });
    } else {
      patchGame(game.id, { score1: team1, score2: team2 }).finally(() => {
        close(true);
      });
    }
  }, [close, team1, team2, game.id]);
  const onClose = useCallback(() => {
    close(false);
  }, [close]);
  return (
    <div className="single-game">
      <h2>
        Spiel: {game.team1.name} vs {game.team2.name}
      </h2>
      <h3>
        Punktestand: {game.score1 ?? 0} - {game.score2 ?? 0}
      </h3>
      {admin && (
        <div>
          <h4>Punktestand ändern</h4>
          <label htmlFor="team1" className="change-score">
            <span
              className="team-clickable"
              onClick={() => {
                setTeam1(15);
              }}>
              {game.team1.name}
            </span>
            <input
              type="number"
              id="team1"
              onChange={onSetTeam1}
              value={team1}
            />
          </label>
          <br />
          <label htmlFor="team2" className="change-score">
            <span
              className="team-clickable"
              onClick={() => {
                setTeam2(15);
              }}>
              {game.team2.name}
            </span>
            <input
              type="number"
              id="team2"
              onChange={onSetTeam2}
              value={team2}
            />
          </label>
          <br />
          <div className="change-score">
            <button type="button" onClick={onClose}>
              Abbrechen
            </button>
            <button type="button" onClick={onSave}>
              Speichern
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleGameView;

// rs as
