import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createKOGame, getScore } from './client';
import { Score, Team } from './api';

const SingleCreateGameView: React.FC<{
  close: (refresh: boolean) => void;
  finalId: number;
  rounds: number;
}> = ({ finalId, close, rounds }) => {
  const [team1, setTeam1] = useState<Team>();
  const [team2, setTeam2] = useState<Team>();
  const [score, setScore] = useState<Score[]>([]);
  useEffect(() => {
    getScore().then((s) => {
      setScore(s.sort((a, b) => a.placement - b.placement));
    });
  }, []);
  const achtlIdx = useMemo(() => {
    return rounds > 3 ? 4 : -1;
  }, [rounds]);
  const viertlIdx = useMemo(() => {
    return rounds > 2 ? 3 : -1;
  }, [rounds]);
  const halbIdx = useMemo(() => {
    return 2;
  }, []);
  const finalIdx = useMemo(() => {
    return 1;
  }, []);
  const name = useMemo(() => {
    if (finalIdx === finalId) {
      return 'Finale';
    }
    if (achtlIdx === finalId) {
      return 'Achtelfinale';
    }
    if (viertlIdx === finalId) {
      return 'Viertelfinale';
    }
    if (halbIdx === finalId) {
      return 'Halbfinale';
    }
    return 'Sechzehntlfinale';
  }, []);
  const onSetTeam1 = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      const teamId = parseInt(e.target.value, 10);
      const t = score.find((sc) => sc.team.id === teamId);
      setTeam1(t?.team);
    },
    [score],
  );
  const onSetTeam2 = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      const teamId = parseInt(e.target.value, 10);
      const t = score.find((sc) => sc.team.id === teamId);
      setTeam2(t?.team);
    },
    [score],
  );
  const onSave = useCallback(() => {
    if (team1 && team2) {
      createKOGame(team1.id, team2.id, finalId).finally(() => {
        close(true);
      });
    }
  }, [close, team1, team2, finalId]);
  const onClose = useCallback(() => {
    close(false);
  }, [close]);
  return (
    <div className="single-game">
      <h2>Spiel erstellen: {name}</h2>

      <div>
        <h2>Spiel erstellen</h2>
        <select id="team1" onChange={onSetTeam1} value={team1?.id ?? -1}>
          <option value={-1}>Wählen</option>
          {score.map((sc) => (
            <option key={'op-1-' + sc.team.id} value={sc.team.id}>
              {sc.team.name} [{sc.team.id}]
            </option>
          ))}
        </select>
        <br />
        <strong>vs</strong>
        <br />
        <select id="team2" onChange={onSetTeam2} value={team2?.id ?? -1}>
          <option value={-1} selected={!team2}>
            Wählen
          </option>
          {score.map((sc) => (
            <option key={'op-1-' + sc.team.id} value={sc.team.id}>
              {sc.team.name} [{sc.team.id}]
            </option>
          ))}
        </select>
        <br />
        <div className="change-score">
          <button type="button" onClick={onClose}>
            Abbrechen
          </button>
          {!!team1 && !!team2 && (
            <button type="button" onClick={onSave}>
              Speichern
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleCreateGameView;

// rs as
