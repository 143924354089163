/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Score } from '../models/Score';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScoreService {

    /**
     * Get Score
     * @returns Score Successful Response
     * @throws ApiError
     */
    public static getScoreScoreGet(): CancelablePromise<Array<Score>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/score/',
        });
    }

}
