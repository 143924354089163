/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Game } from '../models/Game';
import type { GameCreate } from '../models/GameCreate';
import type { GameScore } from '../models/GameScore';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GameService {

    /**
     * Get Amount Pre Rounds
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getAmountPreRoundsGameAmountPreRoundsGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game/amount_pre_rounds',
        });
    }

    /**
     * Get Amount Final Rounds
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getAmountFinalRoundsGameAmountFinalRoundsGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game/amount_final_rounds',
        });
    }

    /**
     * Get Pre Games
     * @param gameRound 
     * @returns Game Successful Response
     * @throws ApiError
     */
    public static getPreGamesGamePreGameRoundGet(
gameRound: number,
): CancelablePromise<Array<Game>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game/pre/{game_round}',
            path: {
                'game_round': gameRound,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Final Games
     * @param finalId 
     * @returns Game Successful Response
     * @throws ApiError
     */
    public static getFinalGamesGameFinalFinalIdGet(
finalId: number,
): CancelablePromise<Array<Game>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game/final/{final_id}',
            path: {
                'final_id': finalId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Score
     * @param gameId 
     * @param requestBody 
     * @returns Game Successful Response
     * @throws ApiError
     */
    public static setScoreGameScoreGameIdPost(
gameId: number,
requestBody: GameScore,
): CancelablePromise<Game> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/game/score/{game_id}',
            path: {
                'game_id': gameId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Game
     * @param requestBody 
     * @returns Game Successful Response
     * @throws ApiError
     */
    public static createGameGamePost(
requestBody: GameCreate,
): CancelablePromise<Game> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/game/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Recalculate Games
     * @returns any Successful Response
     * @throws ApiError
     */
    public static recalculateGamesGameRecalculatePost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/game/recalculate',
        });
    }

    /**
     * Delete Game
     * @param gameId 
     * @returns number Successful Response
     * @throws ApiError
     */
    public static deleteGameGameGameIdDelete(
gameId: number,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/game/{game_id}',
            path: {
                'game_id': gameId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
