import {
  CancelablePromise,
  Game,
  GameScore,
  GameService,
  OpenAPI,
  ScoreService,
  TeamCreate,
  TeamPatch,
  TeamService,
} from './api';

OpenAPI.BASE = 'https://api.watten.kivi.bz.it';

export function getGames(): CancelablePromise<{
  rounds: number;
  games: Game[];
}> {
  return new CancelablePromise<{ rounds: number; games: Game[] }>(
    (resolve, reject) => {
      GameService.getAmountPreRoundsGameAmountPreRoundsGet().then((amount) => {
        const promises: Promise<Game[]>[] = [];
        for (let i = 0; i < amount; i++) {
          promises.push(GameService.getPreGamesGamePreGameRoundGet(i + 1));
        }
        Promise.allSettled(promises).then((results) => {
          let games: Game[] = [];
          results.forEach((result) => {
            if (result.status === 'fulfilled') {
              games = games.concat(result.value);
            }
          });
          resolve({
            rounds: amount,
            games,
          });
        }, reject);
      });
    },
  );
}

export function getGamesKO(): CancelablePromise<{
  rounds: number;
  games: Game[];
}> {
  return new CancelablePromise<{
    rounds: number;
    games: Game[];
  }>((resolve, reject) => {
    GameService.getAmountFinalRoundsGameAmountFinalRoundsGet().then(
      (amountFinalRounds) => {
        const promises: Promise<Game[]>[] = [];
        for (let i = 0; i < amountFinalRounds; i++) {
          promises.push(GameService.getFinalGamesGameFinalFinalIdGet(i + 1));
        }
        Promise.allSettled(promises).then((results) => {
          let games: Game[] = [];
          console.log(results);
          results.forEach((result) => {
            if (result.status === 'fulfilled') {
              games = games.concat(result.value);
            }
          });
          resolve({
            rounds: amountFinalRounds,
            games,
          });
        }, reject);
      },
      reject,
    );
  });
}

export function recalculate() {
  return GameService.recalculateGamesGameRecalculatePost();
}

export function getScore() {
  return ScoreService.getScoreScoreGet();
}

export function patchTeam(teamId: number, team: TeamPatch) {
  return TeamService.patchTeamTeamTeamIdPatch(teamId, team);
}

export function createTeam(team: TeamCreate) {
  return TeamService.createTeamTeamPost(team);
}

export function createKOGame(
  team1Id: number,
  team2Id: number,
  finalId: number,
) {
  return GameService.createGameGamePost({
    gameRound: null,
    team2Id,
    team1Id,
    finalId,
  });
}

export function deleteTeam(team: number) {
  return TeamService.deleteTeamTeamTeamIdDelete(team);
}

export function patchGame(gameId: number, score: GameScore) {
  return GameService.setScoreGameScoreGameIdPost(gameId, score);
}

/**
 * delete a score
 * @param id
 */
export function resetGame(id: number) {
  return GameService.deleteGameGameGameIdDelete(id);
}
