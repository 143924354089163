/* eslint-disable react/no-array-index-key */
import React, {useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil, faRefresh} from '@fortawesome/free-solid-svg-icons';
import {getGamesKO} from './client';
import {Game} from './api';
import SingleGameView from './SingleGameView';
import SingleCreateGameView from "./SingleCreateGameView";

const KOTree: React.FC<{
    admin: boolean;
    active: boolean;
    refresh: number;
    triggerRefresh: () => void;
}> = ({
          admin, active, refresh, triggerRefresh,
      }) => {
    const [games, setGames] = useState<Game[]>([]);
    const [editGame, setEditGame] = useState<Game>();
    const [createGame, setCreategame] = useState<number>();
    const [refreshing, setRefreshing] = useState(false);
    const [rounds, setRounds] = useState(0)
    const [round, setRound] = useState(0);
    const localRefresh = useRef(refresh);
    const refreshGames = useCallback(() => {
        setRefreshing(true);
        getGamesKO().then((g) => {
            setRounds(g.rounds);
            setGames(g.games);
            setRefreshing(false);
        });
    }, []);
    useEffect(() => {
        if (refresh === 0 || refresh > localRefresh.current) {
            refreshGames();
            localRefresh.current = refresh;
        }
        const cancel = setInterval(() => {
            refreshGames();
        }, 60000);
        return () => {
            clearInterval(cancel);
        };
    }, [refresh, refreshGames]);
    const onSetEdit = useCallback(
        (id: number) => () => {
            setEditGame(games.find((g) => g.id === id));
        },
        [games],
    );

    const onSetCreate = useCallback((idx: number) => {
        return () => {
            setCreategame(idx);
        };
    }, []);
    const onCloseEdit = useCallback(
        (r: boolean) => {
            setEditGame(undefined);
            if (r) {
                triggerRefresh();
            }
        },
        [triggerRefresh],
    );
    const sechzehntlIdx = useMemo(() => {
        return rounds > 4 ? 5 : -1;
    }, [rounds]);
    const achtlIdx = useMemo(() => {
        return rounds > 3 ? 4 : -1;
    }, [rounds]);
    const viertlIdx = useMemo(() => {
        return rounds > 2 ? 3 : -1;
    }, [rounds]);
    const halbIdx = useMemo(() => {
        return 2;
    }, []);
    const finalIdx = useMemo(() => {
        return 1;
    }, []);
    const sechzehntl = useMemo(
        () => rounds > 4 ? games
            .filter((g) => g.finalId === sechzehntlIdx) : [],
        [games, rounds, sechzehntlIdx],
    );
    const achtl = useMemo(
        () => rounds > 3 ? games
            .filter((g) => g.finalId === achtlIdx) : [],
        [achtlIdx, games, rounds],
    );
    const viertl = useMemo(
        () => rounds > 2 ? games
            .filter((g) => g.finalId === viertlIdx) : [],
        [games, rounds, viertlIdx],
    );
    const halb = useMemo(
        () => rounds > 1 ? games
            .filter((g) => g.finalId === halbIdx) : [],
        [games, halbIdx, rounds],
    );
    const final = useMemo(
        () => games
            .filter((g) => g.finalId === finalIdx),
        [games, finalIdx],
    );
    const onRoundClicked = useCallback((r: number) => {
        setRound(r);
    }, []);
    const onClickA = useCallback(() => {
        onRoundClicked(0);
    }, [onRoundClicked]);
    const printGames = useCallback((inner: Game[], name: string, totalAmount: number, innerIdx: number) => {
        return (inner.concat(Array(totalAmount).fill(0)) as (Game | number)[])
            .slice(0, totalAmount)
            .map((g, index) => {
                if (typeof g === 'number') {
                    return (
                        <div className="viertl-game " key={`gamev-${index}`}>
                            <div className="teams">
                                <span>TBA</span>
                                <strong>
                                    {name}
                                    {index + 1}
                                </strong>
                                <span>TBA</span>
                            </div>
                            <div className="score">
                                <span>0</span>
                                <span>0</span>
                            </div>

                            {admin && (
                                <button type="button" onClick={onSetCreate.call(this, innerIdx)}>
                                    <FontAwesomeIcon icon={faPencil}/>
                                </button>
                            )}
                        </div>
                    );
                }
                return (
                    <div
                        className="viertl-game "
                        key={`game-${g.id}`}>
                        <div className="teams">
                                  <span>
                                    <strong>[{g.team1.number}]</strong>
                                      {` ${g.team1.name}`}
                                  </span>
                            <strong className="vs">vs.</strong>
                            <span>
                                    <strong>[{g.team2.number}]</strong>
                                {` ${g.team2.name}`}
                                  </span>
                        </div>
                        <div className="score">
                            <strong>{g.score1}</strong>
                            <span> - </span>
                            <strong>{g.score2}</strong>
                        </div>
                        {admin && (
                            <button
                                type="button"
                                onClick={onSetEdit.call(this, g.id)}>
                                <FontAwesomeIcon icon={faPencil}/>
                            </button>
                        )}
                    </div>
                );
            });
    }, [admin, onSetCreate, onSetEdit]);
    const onCloseCreate = useCallback(() => {
        refreshGames();
        setCreategame(undefined);
    }, [refreshGames]);
    console.log(games)
    console.log(achtl)
    console.log(achtlIdx)
    return (
        <div className={`gamesKO${active ? ' active' : ''}`}>
            {editGame && (
                <SingleGameView close={onCloseEdit} admin={admin} game={editGame}/>
            )}
            {!!createGame && admin && (
                <SingleCreateGameView close={onCloseCreate} finalId={createGame} rounds={rounds}/>
            )}
            <h2>
                K.O.-Phase
                {refreshing && (
                    <div className="refreshing">
                        <FontAwesomeIcon icon={faRefresh}/>
                    </div>
                )}
            </h2>
            <div className="row">
                <button type="button" onClick={onClickA}>
                    Alle
                </button>
                {rounds > 4 && (
                    <button type="button" onClick={() => {
                        onRoundClicked(sechzehntlIdx)
                    }}>
                        Sechzehntlfinale
                    </button>
                )}
                {rounds > 3 && (
                    <button type="button" onClick={() => {
                        onRoundClicked(achtlIdx)
                    }}>
                        Achtelfinale
                    </button>
                )}
                {rounds > 2 && (
                    <button type="button" onClick={() => {
                        onRoundClicked(viertlIdx)
                    }}>
                        Viertelfinale
                    </button>
                )}
                {rounds > 1 && (
                    <button type="button" onClick={() => {
                        onRoundClicked(halbIdx)
                    }}>
                        Halbfinale
                    </button>
                )}
                <button type="button" onClick={() => {
                    onRoundClicked(finalIdx)
                }}>
                    Finale
                </button>
            </div>
            <div className="tree">
                {(round === 0 || round === sechzehntlIdx) && sechzehntlIdx !== -1 && (<>
                    <span>Sechzehntlfinale</span>
                    {printGames(sechzehntl, "Sechzehntlfinale", 16, sechzehntlIdx)}
                </>)}
                {(round === 0 || round === achtlIdx) && achtlIdx !== -1 && (<>
                    <span>Achtelfinale</span>
                    {printGames(achtl, "Achtelfinale", 8, achtlIdx)}
                </>)}
                {(round === 0 || round === viertlIdx) && viertlIdx !== -1 && (<>
                    <span>Viertlfinale</span>
                    {printGames(viertl, "Viertlfinale", 4, viertlIdx)}
                </>)}
                {(round === 0 || round === halbIdx) && <span>Halbfinale</span>}
                {(round === 0 || round === halbIdx)
                    && (halb.concat(Array(2).fill(0)) as (Game | number)[])
                        .slice(0, 2)
                        .map((g, index) => {
                            if (typeof g === 'number') {
                                return (
                                    <div className="viertl-game" key={`gameh-${index}`}>
                                        <div className="teams">
                      <span>
                        Gewinner Viertelfinale
                          {index * 2 + 1}
                      </span>
                                            <strong>
                                                Halbfinale
                                                {index + 1}
                                            </strong>
                                            <span>
                        Gewinner Viertelfinale
                                                {index * 2 + 2}
                      </span>
                                        </div>
                                        <div className="score">
                                            <span>0</span>
                                            <span>0</span>
                                        </div>

                                        {admin && (
                                            <button type="button" onClick={onSetCreate.call(this, halbIdx)}>
                                                <FontAwesomeIcon icon={faPencil}/>
                                            </button>
                                        )}
                                    </div>
                                );
                            }
                            return (
                                <div className="viertl-game" key={`game-${g.id}`}>
                                    <div className="teams">
                    <span>
                      <strong>
                        [
                          {g.team1.number}
                          ]
                      </strong>
                        {` ${g.team1.name}`}
                    </span>
                                        <strong className="vs">vs.</strong>
                                        <span>
                      <strong>
                        [
                          {g.team2.number}
                          ]
                      </strong>
                                            {` ${g.team2.name}`}
                    </span>
                                    </div>
                                    <div className="score">
                                        <strong>{g.score1}</strong>
                                        <span> - </span>
                                        <strong>{g.score2}</strong>
                                    </div>
                                    {admin && (
                                        <button type="button" onClick={onSetEdit.call(this, g.id)}>
                                            <FontAwesomeIcon icon={faPencil}/>
                                        </button>
                                    )}
                                </div>
                            );
                        })}
                {(round === 0 || round === finalIdx) && <span>Finale</span>}
                {(round === 0 || round === finalIdx)
                    && (final.concat(Array(2).fill(0)) as (Game | number)[])
                        .slice(0, 2)
                        .map((g, index) => {
                            if (typeof g === 'number') {
                                return (
                                    <div
                                        className={`viertl-game ${index === 0 ? 'finale' : ''}`}
                                        key={`gamef-${index}`}
                                    >
                                        <div className="teams">
                      <span>
                        {index === 0
                            ? 'Gewinner Halbfinale 1'
                            : 'Verlierer Halbfinale 1'}
                      </span>
                                            <strong>
                                                {index === 0 ? 'Finale' : 'Spiel um Platz 3'}
                                            </strong>
                                            <span>
                        {index === 0
                            ? 'Gewinner Halbfinale 2'
                            : 'Verlierer Halbfinale 2'}
                      </span>
                                        </div>
                                        <div className="score">
                                            <span>0</span>
                                            <span>0</span>
                                        </div>
                                        {admin && (
                                            <button type="button" onClick={onSetCreate.call(this, finalIdx)}>
                                                <FontAwesomeIcon icon={faPencil}/>
                                            </button>
                                        )}
                                    </div>
                                );
                            }
                            return (
                                <div
                                    className={`viertl-game ${index === 0 ? 'finale' : ''}`}
                                    key={`game-${g.id}`}
                                >
                                    <div className="teams">
                    <span>
                      <strong>
                        [
                          {g.team1.number}
                          ]
                      </strong>
                        {` ${g.team1.name}`}
                    </span>
                                        <strong className="vs">vs.</strong>
                                        <span>
                      <strong>
                        [
                          {g.team2.number}
                          ]
                      </strong>
                                            {` ${g.team2.name}`}
                    </span>
                                    </div>
                                    <div className="score">
                                        <strong>{g.score1}</strong>
                                        <span> - </span>
                                        <strong>{g.score2}</strong>
                                    </div>
                                    {admin && (
                                        <button type="button" onClick={onSetEdit.call(this, g.id)}>
                                            <FontAwesomeIcon icon={faPencil}/>
                                        </button>
                                    )}
                                </div>
                            );
                        })}
            </div>
        </div>
    );
};

export default KOTree;

//  ss
