import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import { SHA256 } from 'crypto-js';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScoreView from './ScoreView';
import GameView from './GameView';
import KOTree from './KOTree';

const MASTER_PW =
  '7d293b32ca5a0719cb4e63bc213220cc6add8f68a87d729c6500acd86b310527';
const MASTER_PW2 =
  '911cc93dce1815ef547530b30d1260207aa877d6950406325405cbafd1859e96'; // H...
const MASTER_PW3_ADMIN =
  'd335f886594d59dd01f1b8b865e92b3335621fe7e1410823cf7759d6ac4b4d6a'; // jp...

function App() {
  const pw = useRef('');
  const [pwWrong, setPwWrong] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [subAdmin, setSubAdmin] = useState(false);
  const [active, setActive] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const clicked = useRef(0);
  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      pw.current = e.target.value;
    },
    [],
  );
  const onClickHeader = useCallback(() => {
    clicked.current++;
    if (clicked.current === 3) setModalVisible(true);
    setTimeout(() => {
      clicked.current = 0;
    }, 1000);
  }, []);
  const onClose = useCallback(() => {
    setModalVisible(false);
  }, []);
  const onLogin = useCallback(() => {
    const toString = SHA256(pw.current).toString();
    if (toString === MASTER_PW || toString === MASTER_PW3_ADMIN) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          pw: toString,
          date: new Date().getTime(),
        }),
      );
      setAdmin(true);
      setPwWrong(false);
      onClose();
    } else if (toString === MASTER_PW2) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          pw: toString,
          date: new Date().getTime(),
        }),
      );
      setSubAdmin(true);
      setPwWrong(false);
      onClose();
    } else {
      setPwWrong(true);
    }
  }, [onClose]);

  useEffect(() => {
    const creds = localStorage.getItem('user');
    if (creds) {
      const u = JSON.parse(creds);
      if (!!u && typeof u === 'object' && 'pw' in u && 'date' in u) {
        if (u.pw === MASTER_PW || u.pw === MASTER_PW3_ADMIN) {
          setAdmin(true);
          setPwWrong(false);
          onClose();
        } else if (u.pw === MASTER_PW2) {
          setSubAdmin(true);
          setPwWrong(false);
          onClose();
        }
      }
    }
  }, [onClose, onLogin]);
  const onClickGames = useCallback(() => {
    setActive(1);
  }, []);
  const onClickScore = useCallback(() => {
    setActive(0);
  }, []);
  const onClickKO = useCallback(() => {
    setActive(2);
  }, []);
  const triggerRefresh = useCallback(() => {
    setRefresh((r) => r + 1);
  }, []);
  const onLoginEnter = useCallback<React.KeyboardEventHandler>(
    (e) => {
      if (e.key === 'Enter') onLogin();
    },
    [onLogin],
  );
  return (
    <div className="App">
      <header>
        <h1 onClick={onClickHeader}>
          Wattkönig
          {admin ? ' - Admin' : subAdmin ? ' - Moderator' : ''}
          <button type="button" onClick={triggerRefresh} className="do-refresh">
            <FontAwesomeIcon icon={faRefresh} />
          </button>
          {admin ||
            (subAdmin && (
              <button
                onClick={() => {
                  setSubAdmin(false);
                  setAdmin(false);
                  localStorage.setItem('user', '');
                }}>
                Abmelden
              </button>
            ))}
        </h1>
        <nav>
          <button type="button" onClick={onClickScore}>
            Punktestand
          </button>
          <button type="button" onClick={onClickGames}>
            Spiele
          </button>
          <button type="button" onClick={onClickKO}>
            KO-Runden
          </button>
        </nav>
      </header>
      <div className="main">
        <ScoreView
          admin={admin}
          active={active === 0}
          refresh={refresh}
          triggerRefresh={triggerRefresh}
        />
        <GameView
          admin={admin || subAdmin}
          active={active === 1}
          refresh={refresh}
          triggerRefresh={triggerRefresh}
        />
        <KOTree
          admin={admin || subAdmin}
          active={active === 2}
          refresh={refresh}
          triggerRefresh={triggerRefresh}
        />
      </div>
      <div className={`modal ${modalVisible ? '' : 'hidden'}`}>
        <div className="content">
          <label htmlFor="password" className={pwWrong ? 'border-danger' : ''}>
            Password:
            <br />
            <input
              id="password"
              type="password"
              onChange={onChange}
              onKeyUp={onLoginEnter}
              onSubmit={onLogin}
            />
          </label>
          <br />
          <span className="text-danger">
            {pwWrong ? 'Password Falsch' : ''}
          </span>
          <br />
          <button type="button" onClick={onClose}>
            Schließen
          </button>
          <button type="button" onClick={onLogin}>
            Anmelden
          </button>
        </div>
      </div>
      <div className="footer">
        <a href="https://www.kivi.bz.it">
          Powered by &copy; Kivi
          <br />
          Ivan Waldboth | Alexander Klement
        </a>
      </div>
    </div>
  );
}

export default App;
// Re loa d
